import EmailForm from '../components/EmailForm';
import Landing from '../components/Landing';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const md = require('markdown-it')({ html: true });
const dedent = require('dedent-js');

const content1 = md.render(dedent`# Apprendre à coder gratuitement - HTML, CSS et JavaScript`);

const content2 = md.render(dedent`
Au Reacteur, nous sommes passionnés par la programmation !

Depuis 2016, notre objectif est de <b>transmettre notre savoir</b> à tous ceux qui souhaitent apprendre à coder. En effet, en plus d'être créatif et très recherché sur le marché du travail, le métier de Développeur Web et Mobile est réellement passionnant et offre des salaires très attractifs.

Mais avant de se lancer dans ce domaine, il est important de savoir si coder est quelque chose que vous vous voyez faire au quotidien. C'est pourquoi, nous avons concocté <strong>une formation gratuite au code</strong> pour toutes les personnes débutantes et intéressées par la programmation.

## Le contenu de la formation en e-learning

Grâce à deux modules distincts, vous allez pouvoir commencer à apprendre le <strong>HTML/CSS</strong> ainsi que le <strong>JavaScript</strong>.

Cette formation est composée de cours écrits et vidéos, d'exercices à réaliser soi-même, ainsi que de corrections. On vous donne accès gratuitement à notre plateforme, et la bonne nouvelle, c'est que tout est en français !

Ces <strong>cours gratuits en programmation</strong> sont un bon moyen pour vous donner un aperçu de comment nos élèves sont formés pendant le [bootcamp Développeur Web et Mobile](https://www.lereacteur.io/bootcamp-javascript/formation-developpeur-Web-Mobile/) qui est dispensé chez nous, à temps plein ou à temps partiel, en présentiel ou en téléprésentiel, et également en alternance !

## Se préparer au bootcamp en programmation JavaScript

Si un jour, vous décidez de rejoindre le <strong>bootcamp du Reacteur</strong>, cette formation en ligne vous permettra de préparer votre intégration, en commençant à "mettre les mains dans le cambouis". Nous la recommandons à tous nos futurs élèves !

## Comment accéder à notre formation au codage gratuite ?

C'est très simple ! Nous avons juste besoin de votre email pour vous envoyer la procédure de création de compte. Une fois connecté·e, vous aurez accès à notre plateforme Apollo et pourrez commencer à vous former directement.

Faites attention, nous avons souvent des problèmes avec les boîtes mail Microsoft (hotmail, outlook, live, etc.), privilégiez une adresse Gmail dans la mesure du possible. N'oubliez pas de bien vérifier vos SPAMs et/ou votre dossier Promotions (si vous utilisez Gmail), au cas où.

Notre email peut mettre quelques minutes à vous parvenir, pas de panique. 😁

Vous êtes prêt·e ? À vous de jouer ! 👇
`);

const Page = React.memo((props) => {
  const { location } = props;

  const data = useStaticQuery(graphql`
    query {
      xavierInterview: file(relativePath: { eq: "Xavier_interview.png" }) {
        childImageSharp {
          fluid(quality: 50, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Landing
      location={location}
      content={[
        { type: 'md', content: content1 },
        {
          type: 'youtube',
          mainThumbnail: data.xavierInterview.childImageSharp.fluid,
          link: 'https://www.youtube.com/embed/2KF0LMRZ7VE',
        },
        { type: 'md', content: content2 },
      ]}
      pageName="Apprendre à coder gratuitement - JavaScript, HTML et CSS"
      metaTitle="Apprendre à coder gratuitement - JavaScript, HTML et CSS | Le Reacteur"
    >
      <EmailForm
        location={location}
        originPage="freeTraining"
        route="/api/prepa/add-student"
        submitButtonDefaultText="Recevoir l'accès à la formation gratuite"
        datalayerTracking={() => {
          window.dataLayer.push({
            event: 'registerPrepa',
          });
          if (window.lintrk) window.lintrk('track', { conversion_id: 9849660 });
        }}
      />
    </Landing>
  );
});

export default Page;
